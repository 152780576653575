import React from 'react';
import Image from 'next/image';
import Cta from './Cta';
import usePrefix from '@/hooks/usePrefix';
import { CDN2 } from '@/helpers/api';
import ButtonStore from './ButtonStore';
import { useTranslation } from 'react-i18next';
export interface IFeatures {
  full?: boolean;
  landingPage?: boolean;
}

export default function DownloadApp({ full, landingPage }: IFeatures) {
  const prefix = usePrefix();
  const { t } = useTranslation();

  return (
    <div
      className={`
      ${full && 'bg-orange-50'} ${landingPage && `bg-white`} `}
    >
      <div className="container mx-auto px-4 max-w-4xl ">
        <div
          className={`${
            full ? '' : 'shadow-sm'
          } rounded-md flex flex-col px-2 py-4 mb-4 mx-2 bg-orange-50 h-full ${
            landingPage && `bg-white !shadow-none`
          }`}
        >
          <div
            className={`flex flex-col md:flex-row items-center md:gap-8 ${
              landingPage ? 'md:mx-24' : 'md:mx-32'
            }`}
          >
            <Image
              quality={65}
              src={CDN2() + '/staticmyapp/appDownload.webp'}
              alt="Download App"
              width={174}
              height={350}
              style={{ width: '100%', height: '100%' }}
              className="rounded-md"
            />
            <div>
              {landingPage && (
                <h2 className="text-3xl md:text-6xl text-center mt-4 mb-0 font-bold">
                  {t('save_time')}
                </h2>
              )}
              <h2
                className={`text-center font-bold 
              ${
                landingPage
                  ? 'text-xl md:text-3xl md:mt-2'
                  : 'text-3xl md:text-6xl my-4 '
              }
              `}
              >
                {t('download_our_app')}
              </h2>
              <p
                className={`${
                  landingPage ? `text-sm md:text-xl mb-6 mt-2` : ' my-4'
                }`}
              >
                {t('app_description')}
              </p>
              <div className="flex flex-row justify-center gap-4 mb-4">
                <ButtonStore width={160} height={50} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
