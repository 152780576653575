import Image from 'next/image';
import React, { useEffect, Suspense, useState } from 'react';
import SearchLandingExtraB from './SearchLandingExtraB';
import NoData from '@/components2/NoData';
import EnhancedTextExtra from './Landing/EnhancedTextExtra';
import SimpleClickExtraB from './SimpleClickExtraB';
import ReviewsLanding from './ReviewsLanding';
import { formatCurrency } from '@/helpers/format';
import { Button, Collapse } from 'antd';
import PeopleAlso from './PeopleAlso';
import GuideForTravelling from './GuideForTravelling';
import SectionItem from './Landing/SectionItem';
import { DownOutlined, MinusOutlined, PlusOutlined, UpOutlined } from './Icons';
import { CDN1, CDN2 } from '@/helpers/api';
import StoreBtnsExtraB from './StoreBtnsExtraB';
import Title from './Landing/Title';
import TitleHead from '@/components/TitleHead';
import Link from 'next/link';
import { observer } from 'mobx-react';
import HighlightsExtraB from '@/components/HighlightsExtraB';
import Head from 'next/head';
import DownloadApp from './DownloadApp';
import SearchList from '@/components2/SearchList';
import SearchStore from '@/stores/SearchStore';
import { useMobxStores } from '@/stores';
import GoogleNews from './GoogleNews';
import { applyTextSubstitution } from '@/helpers/linkhelper';
import { findCheapestFacility } from '@/utils/tools';
import { useRouter } from 'next/router';
import { getParkingAtPath, getUrlCodeOrSlug } from '@/utils/newparkingatroutes';
import usePrefix from '@/hooks/usePrefix';
import dayjs, { Dayjs } from 'dayjs';
import { canonicalUrl } from '@/helpers/history';
import { Trans, useTranslation } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher';

const { Panel } = Collapse;

function fixBlogImages(
  blogList: Array<{ name: string; image: string; url: string; date: string }>
) {
  const list = blogList.filter(
    (item) =>
      item.image != null &&
      (item.image.endsWith('images/default.png') ||
        item.image.endsWith('images/default2.png') ||
        item.image.endsWith('images/default3.png'))
  );
  if (list.length > 0) {
    list[0].image = `${CDN2()}/staticmyapp/default-landing.webp`;
    for (let i = 1; i < list.length; i++) {
      list[i].image = `${CDN2()}/staticmyapp/default-landing${
        (i % 2) + 2
      }.webp`;
    }
  }
}

function ExtraLandingComponentA({
  landing,
  deviceType,
  selectedAirport,
  checkin,
  checkout,
  airports,
  searchAlternate,
  icheckin,
  icheckout,
  configInfo,
  fixedReviews,
  googleNews,
  baseUrlSite,
  applyChangeDates,
}) {
  fixBlogImages(landing?.extra?.blogs_1);
  fixBlogImages(landing?.extra?.blogs_2);
  const [lowestPrice, setLowestPrice] = useState<number>(null);
  const metaKeywords = landing?.landing_seo_keywords ?? '';

  const {
    searchStore,
  }: {
    searchStore: SearchStore;
  } = useMobxStores();
  let backGroundImage = CDN2() + '/staticmyapp/landing-banner-generic.jpg';
  if (landing.extra.extra_image) {
    backGroundImage = `${CDN1}/upload/${
      configInfo.isSandBoxEnvironment
        ? 1
        : configInfo.isProductionEnvironment
        ? 2
        : 0
    }/${landing.extra.extra_image}`;
  }
  const [clickSearch, setclickSearch] = useState(false);
  const prefix = usePrefix();
  const router = useRouter();
  const { openLowestPrice } = router.query;
  const { t } = useTranslation();

  function hundleClickSearch() {
    setclickSearch(true);
  }
  // TODO: Nao precisa mais de reload, trocar a logica
  useEffect(() => {
    if (openLowestPrice && searchAlternate?.result?.length > 0) {
      const cheapest = findCheapestFacility(searchAlternate?.result);

      const parkingUrl = `${prefix}/${getParkingAtPath(
        cheapest
      )}/${getUrlCodeOrSlug(cheapest)}}`;

      router.replace(parkingUrl);
    }
  }, [openLowestPrice, searchAlternate]);

  const doOpenLowestPrice = async (checkin: Dayjs, checkout: Dayjs) => {
    await searchStore.fetchSearchResult(null, {
      checkin: checkin.toISOString(),
      checkout: checkout.toISOString(),
      _$i: window.btoa(landing?.properties?.airport_id),
    });
    const cheapest = findCheapestFacility(searchStore.filteredFacilities);

    const parkingUrl = `${prefix}/${getParkingAtPath(
      cheapest
    )}/${getUrlCodeOrSlug(cheapest)}`;

    router.replace(parkingUrl);
  };

  const { i18n } = useTranslation();

  const [landingData, setLandingData] = useState(landing);
  useEffect(() => {
    if (i18n.language == 'es' && landing.landing_es) {
      setLandingData(landing.landing_es);
    } else {
      setLandingData(landing);
    }
  }, [i18n.language]);

  return (
    <div
      className={`relative ${
        landing?.extra?.extra_enabled == true ? `landing-extra landing-a` : ``
      }`}
    >
      <TitleHead
        landing={landing}
        searchAlternate={searchAlternate}
        setLowestPrice={setLowestPrice}
      ></TitleHead>
      <Head>
        {metaKeywords && (
          <meta name="keywords" content={metaKeywords} key="keywords" />
        )}
        <link rel="canonical" href={canonicalUrl(baseUrlSite, router)} />
        {landing?.metainfo?.map((meta) => meta.content)}
        {landing?.properties?.landing_display_header}
        {landing?.schemas?.map((schema, index) => (
          <script
            type="application/ld+json"
            key={index}
            dangerouslySetInnerHTML={{
              __html: schema.schema_object,
            }}
          ></script>
        ))}
      </Head>
      <div className="md:h-96 relative">
        {landing?.airport_display_type == 1 ? (
          <div
            style={{ backgroundColor: deviceType === 'mobile' && '#c5cfd3' }}
          >
            {deviceType !== 'mobile' && (
              <Image
                src={CDN2() + '/staticmyapp/bg-search-city.svg'}
                className="z-0 w-full object-cover min-h-64 h-full absolute object-bottom"
                alt="City Background"
                fill
                style={{ objectFit: 'cover' }}
                quality={70}
              />
            )}
            <div className="container mx-auto px-4 py-6 z-10 relative">
              <div className="flex flex-col justify-center text-center">
                <h1
                  className={`${
                    landing.landing_es
                      ? 'text-3xl md:text-5xl'
                      : 'text-3xl md:text-6xl'
                  } leading-none w-full text-center pb-4 font-bold text-white drop-shadow-lg shadow-black`}
                >
                  {landingData?.properties?.display_name}
                </h1>
                <span className="hidden md:block font-bold text-white mb-4">
                  Starting From $
                  {lowestPrice?.toFixed(2) ?? landing?.properties?.price} /day
                </span>
              </div>
              <SearchLandingExtraB
                searchType="city"
                title={landing?.properties?.seo_title}
                isNewLanding={true}
                selectedAirport={selectedAirport}
                applyChangeDates={applyChangeDates}
                openLowestPrice={doOpenLowestPrice}
                deviceType={deviceType}
                checkin={checkin ?? undefined}
                checkout={checkout ?? undefined}
                clickSearch={hundleClickSearch}
              />
            </div>
          </div>
        ) : (
          <div>
            <Image
              src={backGroundImage}
              className="brightness-75 opacity-90"
              alt="Airport Background"
              fill
              style={{ objectFit: 'cover' }}
              quality={70}
              fetchPriority="high"
              loading="eager"
            />
            <div className="container mx-auto px-4 py-6 z-10 relative">
              <div className="flex flex-col justify-center text-center">
                <h1
                  className={`${
                    landing.landing_es
                      ? 'text-3xl md:text-5xl'
                      : 'text-3xl md:text-6xl'
                  } leading-none w-full text-center pb-4 font-bold text-white drop-shadow-lg shadow-black`}
                >
                  {landingData?.properties?.display_name}
                </h1>
                <span className="hidden md:block font-bold text-white mb-4">
                  {t('starting_from', {
                    price:
                      lowestPrice?.toFixed(2) ?? landing?.properties?.price,
                  })}
                </span>
              </div>
              <SearchLandingExtraB
                searchType="airport"
                airports={airports}
                title={landing?.properties?.seo_title}
                selectedAirport={selectedAirport}
                deviceType={deviceType}
                applyChangeDates={applyChangeDates}
                openLowestPrice={doOpenLowestPrice}
                checkin={icheckin}
                checkout={icheckout}
                isNewLanding={true}
                clickSearch={hundleClickSearch}
                buttonBackground={`${
                  landing?.extra?.button_background
                    ? landing?.extra?.button_background
                    : '#3D68A5'
                }`}
                buttonTextColor={`${
                  landing?.extra?.button_text_color
                    ? landing?.extra?.button_text_color
                    : '#FFFFFF'
                }`}
              />
            </div>
          </div>
        )}
        {landing?.extra?.extra_enabled == true && (
          <Suspense>
            <StoreBtnsExtraB googleStars={4.5} appleStars={4.5} />
          </Suspense>
        )}
      </div>
      {landing.landing_es && <LanguageSwitcher extraClass="bg-orange-50" />}
      <h2
        className={`bg-orange-50 title2 leading-none w-full text-center pb-2 pt-4 font-bold `}
      >
        {t('parking_options', {
          airport_initials: landing?.properties?.airport_initials,
        })}
      </h2>
      <p className="bg-orange-50 text-sm px-6 md:text-2xl md:px-24 text-center pt-8 pb-2">
        {t('find_parking', {
          display_name: landing?.properties?.airport_name,
          airport_initials: landing?.properties?.airport_initials,
        })}
      </p>
      <SearchArea
        deviceType={deviceType}
        landing={landing}
        configInfo={configInfo}
        icheckin={icheckin}
        icheckout={icheckout}
        searchAlternate={searchAlternate}
      />

      <h2
        className={`title2 px-4 leading-none w-full text-center pb-2 pt-10 font-bold `}
      >
        {t('why_choose_on_air', {
          display_name: landing?.airport_city,
        })}
      </h2>
      <p className="text-sm px-6 md:text-2xl mt-8 md:px-24">
        <Trans
          i18nKey="on_air_parking_description"
          values={{
            airport_name: landing?.properties?.airport_name,
            airport_initials: landing?.properties?.airport_initials,
          }}
        />
      </p>
      <p className="text-sm px-6 md:text-2xl mt-8 md:px-24">
        {t('savings_description')}
      </p>

      {landingData?.extra?.extra_enabled == true && (
        <Suspense>
          <ReviewsLanding
            isNewLanding
            airport={landingData?.properties?.airport_name}
            reviews={fixedReviews}
          />

          <h2
            className={`title2  px-4 leading-none w-full text-center pb-2 pt-10 font-bold `}
          >
            {t('resources', {
              airport_name: landingData?.properties?.airport_name,
            })}
          </h2>

          <Suspense>
            <div
              className={`high-content ${
                landingData?.extra?.extra_enabled == true
                  ? `bg-orange-50`
                  : `bg-white`
              } flex flex-col justify-center gap-2 px-4 md:px-24 h-auto py-16 -mt-24 -z-10`}
            >
              <article>
                <div className={`flex flex-row justify-center items-center`}>
                  <h3
                    className={`leading-none text-center pb-2 pt-10 font-bold title3`}
                  >
                    {t('parking_tips_faqs')}
                  </h3>

                  <p className="hidden md:block mt-8">
                    <Image
                      src={CDN2() + '/staticmyapp/interrogation.png'}
                      width={60}
                      height={100}
                      alt="icon"
                    />
                  </p>
                  <p className="md:hidden block mt-4">
                    <Image
                      src={CDN2() + '/staticmyapp/interrogation.png'}
                      width={30}
                      height={50}
                      alt="icon"
                    />
                  </p>
                </div>
                <div className="high-content flex flex-col gap-2">
                  {landingData?.sections
                    ?.sort((a, b) => {
                      return a.order - b.order;
                    })
                    .map(
                      (section, index) =>
                        section.type == 'Accordion' && (
                          <Collapse
                            bordered={
                              landingData?.extra?.extra_enabled == true
                                ? false
                                : true
                            }
                            className="--header-border"
                            key={index}
                            expandIconPosition="right"
                            expandIcon={({ isActive }) => (
                              <span>
                                {isActive ? (
                                  <MinusOutlined className="text-black" />
                                ) : (
                                  <PlusOutlined className="text-black" />
                                )}
                              </span>
                            )}
                          >
                            <Panel
                              header={
                                <h4 className="font-bold">{section?.tag}</h4>
                              }
                              key={index}
                            >
                              <SectionItem
                                section={section}
                                secIndex={index}
                                configInfo={configInfo}
                                fallbackImageAlt={applyTextSubstitution(
                                  landingData?.page_title,
                                  { lowestPrice }
                                )}
                                parameters={{ lowestPrice }}
                                isNewLanding
                              />
                            </Panel>
                          </Collapse>
                        )
                    )}
                </div>
              </article>
            </div>
          </Suspense>
          <PeopleAlso isNewLanding items={landing?.extra?.blogs_1} />
          <GoogleNews
            isNewLanding
            airport={landing?.properties?.airport_name}
            googleNews={googleNews}
          />

          <div>
            <div className="block md:hidden">
              <GuideForTravelling
                isNewLanding
                airport={landing?.airport_city}
                items={landing?.extra?.blogs_2}
                isMobile
              />
            </div>
            <div className="hidden md:block">
              <GuideForTravelling
                isNewLanding
                airport={landing?.airport_city}
                items={landing?.extra?.blogs_2}
              />
            </div>
          </div>
        </Suspense>
      )}
    </div>
  );
}

export default ExtraLandingComponentA;

const SearchAreaM = ({
  landing,
  configInfo,
  icheckin,
  icheckout,
  deviceType,
  searchAlternate,
}) => {
  const {
    searchStore,
  }: {
    searchStore: SearchStore;
  } = useMobxStores();
  const { t } = useTranslation();

  useEffect(() => {
    searchStore.fetchSearchResult(null, {
      checkin: icheckin.toISOString(),
      checkout: icheckout.toISOString(),
      _$i: window.btoa(landing?.properties?.airport_id),
    });
  }, [landing, landing?.properties, searchStore, icheckin, icheckout]);

  const filteredFacilities =
    searchStore.filteredFacilities ?? searchAlternate?.result;
  const showLowestPriceMessage = filteredFacilities?.some(
    (item) => item.facility_disable_lowest_price === 0
  );
  return (
    <>
      <div className={`bg-orange-50 flex flex-col pb-8 md:pb-16 `}>
        {filteredFacilities?.length === 0 ? (
          <NoData
            imageNoData
            textNoData="Bummer! Nothing now, but new facilities coming soon!"
            bgImageWhite
            withSubscribe={true}
            airportId={landing?.properties?.airport_id}
          />
        ) : (
          <div
            className={`md:mx-auto md:overflow-hidden xl:px-24 p-4 ${
              filteredFacilities?.length >= 2 &&
              'grid grid-cols-1 2xl:grid-cols-2 gap-x-4'
            }  ${filteredFacilities?.length === 1 && 'justify-center'}`}
          >
            <SearchList
              configInfo={configInfo}
              searchAlternate={searchAlternate}
              checkin={icheckin.format('YYYY-MM-DD')}
              checkout={icheckout.format('YYYY-MM-DD')}
              deviceType={deviceType}
              isNewLanding
            />
          </div>
        )}
        {showLowestPriceMessage && (
          <p className="text-[0.625rem] ml-2 mt-1.5 mb-4 md:mb-2 md:text-sm text-center">
            {t('price_match_guarantee')}
          </p>
        )}
      </div>
    </>
  );
};

const SearchArea = observer(SearchAreaM);
